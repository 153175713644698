import * as actionTypes from './actions';
import { persistReducer } from 'redux-persist';
import localforage from 'localforage';

const initialState = {
  productList: [],
  cart: [],
  cart_id: 1
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_TO_CART:
      const newItem = { ...action.item };
      newItem['cartId'] = state.cart_id;
      newItem['price'] = {
        amount: newItem.price.amount * newItem.quantity,
        displayCost: parseFloat(
          parseFloat(newItem.price.amount / 100) * newItem.quantity
        ).toFixed(2)
      };
      const updatedCart = [...state.cart, newItem];
      return {
        ...state,
        cart: updatedCart,
        cart_id: state.cart_id + 1
      };

    case actionTypes.REMOVE_FROM_CART:
      const prevCart = [...state.cart];
      const newCart = prevCart.filter(
        (item) => item.cartId !== action.item.cartId
      );
      return {
        ...state,
        cart: newCart
      };

    case actionTypes.RESET_ORDER_STATE_TO_INITIAL_STATE:
      return {
        cart: [],
        cart_id: 1,
        productList: []
      };

    case actionTypes.SET_PRODUCTS:
      return {
        ...state,
        productList: [...state.productList, ...action.products]
      };

    case actionTypes.HANDLE_MODIFIED_PRODUCTS:
      const modifiedIds = [
        ...new Set(action.products.map((product) => product.docId))
      ];
      const updatedProducts = state.productList.filter(
        (product) => !modifiedIds.includes(product.docId)
      );
      return {
        ...state,
        productList: [...updatedProducts, ...action.products]
      };

    case actionTypes.RESET_PRODUCT_INFO:
      return {
        ...state,
        productList: []
      };

    default:
      return state;
  }
};

const persistConfig = {
  key: 'order',
  storage: localforage,
  blacklist: ['productList']
};

export default persistReducer(persistConfig, reducer);

import React, { useState, useContext } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid, Typography, TextField, InputAdornment } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/styles';
import RadioGroup from './statusDialogRadioGroup';
import Context from '../../screens/admin/context';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';

const useStyles = makeStyles({
  root: {
    minHeight: 200,
    minWidth: 400
  },
  formTitle: {
    fontFamily: 'Roboto Mono',
    fontSize: 15,
    fontWeight: '400'
  },
  buttonProgress: {
    color: 'black',
    position: 'relative',
    bottom: 25,
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  errorMessage: {
    color: 'red',
    fontFamily: 'Roboto Mono',
    fontSize: 14,
    textAlign: 'center',
    paddingLeft: '10px'
  },
  warningMessage: {
    color: 'black',
    fontFamily: 'Roboto Mono',
    fontSize: 14,
    textAlign: 'center',
    paddingLeft: '10px'
  }
});

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'black'
    },
    '& .MuiFilledInput-underline:after': {
      borderBottomColor: 'black'
    },
    '& .MuiFilledInput-root': {
      '& fieldset': {
        borderColor: 'black'
      },
      '&:hover fieldset': {
        borderColor: 'black'
      },
      '&.MuiFilledInput-focused fieldset': {
        borderColor: 'black'
      }
    },
    '& .MuiFilledInput-underline.Mui-error:after': {
      borderBottomColor: 'red'
    }
  }
})(TextField);

const StatusDialog = ({ open, handleClose, row }) => {
  const classes = useStyles();
  const { updateOrderStatus } = useContext(Context);
  const [value, setValue] = useState(row.status);
  const [errorMessage, setErrorMessage] = useState('');
  const [trackingNum, setTrackingNum] = useState('');
  const [refundAmount, setRefundAmount] = useState(0);

  const handleChange = (event) => {
    setErrorMessage('');
    setValue(event.target.value);
  };

  const handleSave = () => {
    if (value === 'shipped') {
      if (!trackingNum) {
        return setErrorMessage('TRACKING NUMBER IS REQUIRED');
      }
      setErrorMessage('');
      updateOrderStatus({
        status: value,
        docId: row.docId,
        total: row.total,
        contactFields: row.contactFields,
        chargeId: row.charge.id || row.charge,
        shippingFields: row.shippingFields,
        selectedShipping: row.selectedShipping,
        cart: row.cart,
        subTotal: row.subTotal,
        tax: row.tax,
        tracking: trackingNum
      });
      handleClose();
      return;
    }
    if (value === 'full refund') {
      updateOrderStatus({
        status: value,
        docId: row.docId,
        chargeId: row.charge.id || row.charge
      });
      handleClose();
      return;
    }
    if (value === 'partial refund') {
      if (!refundAmount) {
        return setErrorMessage('REFUND AMOUNT IS REQUIRED');
      }
      updateOrderStatus({
        status: value,
        docId: row.docId,
        chargeId: row.charge.id || row.charge,
        refundAmount: Math.round(parseFloat(refundAmount) * 100),
        total: row.total,
        contactFields: row.contactFields,
        shippingFields: row.shippingFields,
        selectedShipping: row.selectedShipping,
        cart: row.cart,
        subTotal: row.subTotal,
        tax: row.tax
      });
      handleClose();
      return;
    }
    updateOrderStatus({
      status: value,
      docId: row.docId
    });
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} scroll="paper">
      <DialogTitle id="title">EDIT ORDER STATUS</DialogTitle>
      <DialogContent dividers={true}>
        <Grid container direction="column" className={classes.root}>
          <RadioGroup
            options={[
              'confirmed',
              'shipped',
              'full refund',
              'partial refund',
              'delivered'
            ]}
            handleChange={handleChange}
            value={value}
          />
          {value === 'shipped' && (
            <Grid
              CartItem
              container
              direction="row"
              justify="center"
              alignItems="center"
              style={{ padding: '15px 0px 10px 0px' }}
            >
              <WarningIcon style={{ color: 'black' }} />
              <Typography className={classes.warningMessage}>
                PLEASE MAKE SURE TRACKING NUMBER IS CORRECT!
              </Typography>
            </Grid>
          )}

          {value === 'shipped' && (
            <CssTextField
              // error={fields[key].error}
              variant="filled"
              size={'small'}
              fullWidth
              placeholder={'TRACKING #'}
              value={trackingNum}
              label={'TRACKING #'}
              inputProps={{
                style: {
                  fontFamily: 'Roboto Mono',
                  padding: trackingNum ? '15px' : '15px'
                }
              }}
              InputLabelProps={{
                shrink: true,
                style: {
                  display: 'none',
                  fontFamily: 'Roboto Mono'
                }
              }}
              onChange={(e) => setTrackingNum(e.target.value)}
            />
          )}
          {value === 'partial refund' && (
            <Grid
              CartItem
              container
              direction="row"
              justify="center"
              alignItems="center"
              style={{ padding: '15px 0px 10px 0px' }}
            >
              <WarningIcon style={{ color: 'black' }} />
              <Typography className={classes.warningMessage}>
                SET AN AMOUNT TO REFUND
              </Typography>
            </Grid>
          )}
          {value === 'partial refund' && (
            <CssTextField
              // error={fields[key].error}
              variant="filled"
              size={'small'}
              fullWidth
              placeholder={'REFUND AMOUNT'}
              value={refundAmount}
              label={'REFUND AMOUNT'}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    style={{ margin: 0, position: 'relative', top: 1 }}
                  >
                    $
                  </InputAdornment>
                )
              }}
              inputProps={{
                style: {
                  fontFamily: 'Roboto Mono',
                  padding: '15px 15px 15px 2px'
                }
              }}
              InputLabelProps={{
                shrink: true,
                style: {
                  display: 'none',
                  fontFamily: 'Roboto Mono'
                }
              }}
              onChange={(e) => setRefundAmount(e.target.value)}
              type="number"
            />
          )}
          {errorMessage && (
            <Grid
              CartItem
              container
              direction="row"
              justify="center"
              alignItems="center"
              style={{ padding: '10px 0px 10px 0px' }}
            >
              <ErrorIcon style={{ color: 'red' }} />
              <Typography className={classes.errorMessage}>
                {errorMessage}
              </Typography>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          CANCEL
        </Button>
        <Button onClick={handleSave} color="primary">
          SAVE
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StatusDialog;

import React, { useState, useContext } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  Grid,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel
} from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/styles';
import Context from '../../screens/admin/context';

const useStyles = makeStyles({
  formTitle: {
    fontFamily: 'Roboto Mono',
    fontSize: 15,
    fontWeight: '400'
  },

  image: {
    width: 29,
    height: 25
  },

  buttonProgress: {
    color: 'black',
    position: 'relative',
    bottom: 25,
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  errorMessage: {
    color: 'red',
    fontFamily: 'Roboto Mono',
    fontSize: 14,
    textAlign: 'center',
    paddingLeft: '10px'
  }
});

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'black'
    },
    '& .MuiFilledInput-underline:after': {
      borderBottomColor: 'black'
    },
    '& .MuiFilledInput-root': {
      '& fieldset': {
        borderColor: 'black'
      },
      '&:hover fieldset': {
        borderColor: 'black'
      },
      '&.MuiFilledInput-focused fieldset': {
        borderColor: 'black'
      }
    },
    '& .MuiFilledInput-underline.Mui-error:after': {
      borderBottomColor: 'red'
    }
  }
})(TextField);

const FormComponent = (props) => {
  const { fields, onChange } = props;
  const classes = useStyles();
  return (
    <Grid container direction="column" alignItems="center" xs>
      {Object.keys(fields).map((key) => {
        if (fields[key].type === 'checkbox') {
          return (
            <Grid item style={{ padding: '5px 10px' }}>
              <FormControlLabel
                checked={fields[key].value}
                control={<Checkbox color="black" />}
                label={fields[key].label}
                labelPlacement="end"
                onChange={(e) => onChange(e.target.checked, key)}
              />
            </Grid>
          );
        }
        if (fields[key].type === 'text' || fields[key].type === 'number') {
          return (
            <Grid
              item
              container
              direction="row"
              style={{ padding: '5px 10px' }}
            >
              {fields[key].label && (
                <Grid item style={{ padding: '0' }}>
                  <Typography className={classes.formTitle}>
                    {fields[key].label}
                  </Typography>
                </Grid>
              )}
              <CssTextField
                error={fields[key].error}
                variant="filled"
                size={'small'}
                fullWidth
                placeholder={fields[key].label}
                value={fields[key].value}
                label={fields[key].label}
                inputProps={{
                  style: {
                    fontFamily: 'Roboto Mono',
                    padding: fields[key].value ? '15px' : '15px'
                  }
                }}
                InputLabelProps={{
                  shrink: true,
                  style: {
                    display: 'none',
                    fontFamily: 'Roboto Mono'
                  }
                }}
                onChange={(e) => onChange(e.target.value, key)}
                type={fields[key].type}
              />
            </Grid>
          );
        }
      })}
    </Grid>
  );
};

const ProductDialog = ({ open, handleClose, product }) => {
  const classes = useStyles();
  const { updateProduct, products } = useContext(Context);
  const [productFields, setProductFields] = useState({
    enabled: {
      label: 'ENABLED',
      error: false,
      value: product.enabled,
      required: true,
      type: 'checkbox'
    },
    title: {
      label: 'TITLE',
      error: false,
      value: product.title,
      required: true,
      type: 'text'
    },
    price: {
      label: 'PRICE',
      error: false,
      value: parseFloat(product.price.amount / 100),
      required: true,
      type: 'number'
    },
    position: {
      label: 'POSITION',
      error: false,
      value: product.position,
      required: true,
      type: 'number'
    }
  });
  const [stockFields, setStockFields] = useState(product.stock);
  const [stockError, setStockError] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const onProductChange = (value, key) => {
    const updatedFields = { ...productFields };
    updatedFields[key] = { ...updatedFields[key], value };
    setProductFields(updatedFields);
  };

  const onStockChange = (value, size) => {
    const updatedFields = { ...stockFields };
    updatedFields[size] = Number(value);
    setStockFields(updatedFields);
  };

  const handleSave = () => {
    setErrorMessage('');
    const updatedProductFields = { ...productFields };
    const updatedStockFields = { ...stockFields };
    let hasError = false;
    Object.keys(updatedProductFields).forEach((field) => {
      if (field === 'title') {
        if (!updatedProductFields[field].value) {
          updatedProductFields[field].error = true;
          hasError = true;
        } else {
          updatedProductFields[field].error = false;
        }
      }
      if (field === 'price') {
        if (!updatedProductFields[field].value) {
          updatedProductFields[field].error = true;
          hasError = true;
        } else {
          updatedProductFields[field].error = false;
        }
      }
      if (field === 'position') {
        if (!updatedProductFields[field].value) {
          updatedProductFields[field].error = true;
          hasError = true;
        } else {
          updatedProductFields[field].error = false;
        }
      }
    });

    let newStockErrors = [];

    Object.keys(updatedStockFields).forEach((size) => {
      if (updatedStockFields[size] < 0) {
        newStockErrors.push(size);
        hasError = true;
      }
    });

    if (newStockErrors.length > 0) {
      setStockError(newStockErrors);
    } else {
      setStockError([]);
    }
    if (hasError) {
      return setErrorMessage('FILL IN REQUIRED FIELDS');
    }

    // Update product info
    updateProduct(
      updatedProductFields,
      updatedStockFields,
      product.docId,
      product.imageUrl
    );

    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} scroll="paper" fullWidth>
      <DialogTitle id="title">EDIT ITEM</DialogTitle>
      <DialogContent dividers={true}>
        <FormComponent fields={productFields} onChange={onProductChange} />
        <Grid item style={{ padding: '10px 15px' }}>
          <Typography className={classes.formTitle}>STOCK</Typography>
        </Grid>
        {Object.keys(stockFields).map((size) => {
          return (
            <Grid
              item
              container
              direction="row"
              style={{ padding: '5px 10px' }}
            >
              {size && (
                <Grid item style={{ padding: '0' }}>
                  <Typography className={classes.formTitle}>{size}</Typography>
                </Grid>
              )}
              <CssTextField
                error={stockError.includes(size)}
                variant="filled"
                size={'small'}
                fullWidth
                placeholder={size}
                value={stockFields[size]}
                label={size}
                inputProps={{
                  style: {
                    fontFamily: 'Roboto Mono',
                    padding: stockFields[size] ? '15px' : '15px'
                  }
                }}
                InputLabelProps={{
                  shrink: true,
                  style: {
                    display: 'none',
                    fontFamily: 'Roboto Mono'
                  }
                }}
                onChange={(e) => onStockChange(e.target.value, size)}
                type={'number'}
              />
            </Grid>
          );
        })}
      </DialogContent>
      <Grid item style={{ padding: '10px' }}>
        <Typography className={classes.errorMessage}>{errorMessage}</Typography>
      </Grid>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          CANCEL
        </Button>
        <Button onClick={handleSave} color="primary">
          SAVE
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProductDialog;

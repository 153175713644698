export const SET_AUTH_FIELDS = 'SET_AUTH_FIELDS';
export const AUTH_VALIDATION = 'AUTH_VALIDATION';
export const AUTH_VALIDATION_FAILED = 'AUTH_VALIDATION_FAILED';
export const AUTH_VALIDATION_SUCCESS = 'AUTH_VALIDATION_SUCCESS';
export const SET_ORDERS = 'SET_ORDERS';
export const SET_ADMIN_PRODUCTS = 'SET_ADMIN_PRODUCTS';
export const SET_NAV_KEY = 'SET_NAV_KEY';
export const HANDLE_MODIFIED_ORDERS = 'HANDLE_MODIFIED_ORDERS';
export const UPDATE_ORDER_STATUS = 'UPDATE_ORDER_STATUS';
export const UPDATE_ORDER_STATUS_SUCCESS = 'UPDATE_ORDER_STATUS_SUCCESS';
export const UPDATE_ORDER_STATUS_FAILED = 'UPDATE_ORDER_STATUS_FAILED';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS';
export const UPDATE_PRODUCT_FAILED = 'UPDATE_PRODUCT_FAILED';
export const HANDLE_MODIFIED_PRODUCTS = 'HANDLE_MODIFIED_PRODUCTS';
export const SEND_BULK_EMAIL = 'SEND_BULK_EMAIL';
export const SEND_BULK_EMAIL_SUCCESS = 'SEND_BULK_EMAIL_SUCCESS';
export const SEND_BULK_EMAIL_FAILED = 'SEND_BULK_EMAIL_FAILED';

import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  TextField,
  Card,
  CardContent,
  Tooltip
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/styles';
import CardHeader from './Card/CardHeader';
import CardIcon from './Card/CardIcon';
import CardFooter from './Card/CardFooter';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#212121',
    width: '100%',
    minHeight: '100vh',
    paddingTop: 25
  },
  image: {
    width: 125,
    height: 115
  },
  cardTitle: {
    fontFamily: 'Roboto Mono',
    color: 'black',
    fontSize: 18,
    textAlign: 'left',
    margin: 0,
    padding: 5
  },
  cardPrice: {
    fontFamily: 'Roboto Mono',
    color: 'black',
    fontSize: 18,
    textAlign: 'left',
    padding: 5,
    margin: 0
  },
  stockSize: {
    backgroundColor: '#F3A738',
    borderRadius: 5,
    margin: '10px 15px',
    padding: 10,
    width: 90,
    height: 70
  },
  stockText: {
    fontFamily: 'Roboto Mono',
    color: 'black',
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'center'
  },
  stockSizeText: {
    fontFamily: 'Roboto Mono',
    color: 'black',
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center'
  },
  position: {
    width: 35,
    height: 35,
    borderRadius: 50,
    backgroundColor: '#A288E3',
    position: 'relative',
    textAlign: 'center',
    fontFamily: 'Roboto Mono',
    fontSize: 22,
    fontWeight: 'bold',
    top: 15,
    right: -5,
    zIndex: 99
  },
  disabled: {
    opacity: 0.2
  }
});

const ProductItem = ({ product, onClick }) => {
  const classes = useStyles();
  const { title, price, imageUrl, position, stock, enabled, sizes } = product;

  const caluclateStock = (stock) => {
    return Object.keys(stock).reduce((acc, curr) => stock[curr] + acc, 0);
  };

  return (
    <Grid
      item
      xs={12}
      md={12}
      lg={6}
      style={{ padding: 15 }}
      className={!enabled ? classes.disabled : null}
      justify="center"
      alignItems="center"
    >
      <div className={classes.position}>{position}</div>
      <Card style={{ cursor: 'pointer' }} onClick={onClick}>
        <CardHeader color="info" icon stats>
          <Grid container direction="row" justify="space-between">
            <Grid item container direction="row">
              <Grid
                item
                container
                xs={12}
                sm={3}
                md={3}
                lg={3}
                direction="row"
                justify="center"
              >
                <CardIcon color="info">
                  <img
                    src={imageUrl}
                    alt={`${title}`}
                    className={classes.image}
                  />
                </CardIcon>
              </Grid>
              <Grid item container xs direction="column" justify="flex-start">
                <Grid item container direction="row" justify="space-between">
                  <p className={classes.cardTitle}>{title}</p>
                  <p className={classes.cardPrice}>{price.displayCost}</p>
                </Grid>
                <Grid item container direction="row" justify="space-between">
                  <p className={classes.cardTitle}>STOCK AVAILABLE</p>
                  <p className={classes.cardPrice}>{caluclateStock(stock)}</p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardHeader>
        <CardContent style={{ padding: '15px 0px 0px 0px' }}>
          <Grid container direction="column">
            <Grid item container direction="row" justify="space-evenly">
              {sizes.map((size) => {
                return (
                  <Grid
                    container
                    direction="column"
                    className={classes.stockSize}
                  >
                    <span className={classes.stockSizeText}>{size}</span>
                    <span className={classes.stockText}>{stock[size]}</span>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default ProductItem;

import { createSelector } from 'reselect';

const adminState = (state) => state.admin;

export const getAuthFields = createSelector(
  adminState,
  (state) => state.authFields
);

export const getSortedOrders = createSelector(adminState, (state) => {
  const sortedOrders = state.orders.sort(
    (a, b) => b.date._seconds - a.date._seconds
  );
  return sortedOrders;
});

export const getSortedProducts = createSelector(adminState, (state) => {
  const sortedProducts = state.products.sort((a, b) => a.position - b.position);
  return sortedProducts;
});

export const getGrossTotal = createSelector(adminState, (state) => {
  const reducedTotal = state.orders.reduce(
    (acc, curr) => curr.total.amount + acc,
    0
  );
  const grossTotal = (reducedTotal / 100).toFixed(2);
  return grossTotal;
});

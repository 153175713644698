export const orderHeaders = {
  columnData: [
    { name: 'docId', title: 'ORDER #' },
    {
      name: 'fullName',
      title: 'NAME',
      getCellValue: (row) => ({
        firstName: row.contactFields['firstName'].value,
        lastName: row.contactFields['lastName'].value
      })
    },
    {
      name: 'date',
      title: 'DATE',
      getCellValue: (row) => {
        return {
          date: row.date.seconds
        };
      }
    },

    { name: 'status', title: 'STATUS' },

    {
      name: 'total',
      title: 'TOTAL',
      getCellValue: (row) => {
        return {
          total: row.total.displayCost
        };
      }
    }
  ],
  columnWidth: [
    { columnName: 'docId', width: 100, align: 'left' },
    { columnName: 'fullName', width: 100, align: 'left' },
    { columnName: 'date', width: 100, align: 'left' },
    { columnName: 'status', width: 100, align: 'left' },
    { columnName: 'total', width: 100, align: 'left' }
  ]
};

export const productHeaders = {
  columnData: [
    { name: 'docId', title: 'PRODUCT #' },
    { name: 'title', title: 'TITLE' },
    {
      name: 'price',
      title: 'PRICE',
      getCellValue: (row) => {
        return {
          price: row.price.displayCost
        };
      }
    }
  ],
  columnWidth: [
    { columnName: 'docId', width: 100, align: 'left' },
    { columnName: 'title', width: 100, align: 'left' },
    { columnName: 'price', width: 100, align: 'left' }
  ]
};

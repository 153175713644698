import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import Home from './Home';
import Drawer from '../../components/home/drawer';
import Context from './context';
import screenGlobal from '../../store/global';
import screenOrder from '../../store/order';
import * as screenOrderSelectors from '../../store/order/selectors';

import { db } from '../../firebase';

const useStyles = makeStyles({
  root: {
    background:
      'linear-gradient(141deg, #2cb5e8 0%, #6ED4D3 25%, #6ED4D3 51%, #0fb8ad 75%, #0B8A81 100%)',
    height: '100%'
  }
});

const Wrapper = ({ ...other }) => {
  const classes = useStyles();

  const [productsLoading, setProductsLoading] = useState(false);

  const { productList, setProducts, handleModifiedProducts } = other;

  const uniqueProductIds = [
    ...new Set(productList.map((product) => product.docId))
  ];

  const handleAddedProducts = (products) => {
    setProducts(products);
    setProductsLoading(false);
  };

  const handleRemovedProducts = (products) => {
    const updatedProducts = [...productList, ...products];
    // setProducts(updatedProducts);
  };

  useEffect(() => {
    const unsubscribe = db
      .collection('products')
      .onSnapshot(function (snapshot) {
        setProductsLoading(true);
        let addedProducts = [];
        let modifiedProducts = [];
        let removedProducts = [];
        snapshot.docChanges().forEach((change) => {
          if (change.type === 'added') {
            const product = change.doc.data();
            if (
              product &&
              product.docId &&
              !uniqueProductIds.includes(product.docId)
            ) {
              addedProducts.push(product);
            }
          }
          if (change.type === 'modified') {
            const product = change.doc.data();
            modifiedProducts.push(product);
          }
          if (change.type === 'removed') {
            const product = change.doc.data();
            removedProducts.push(product);
          }
        });
        if (addedProducts.length !== 0) {
          handleAddedProducts(addedProducts);
        }
        if (modifiedProducts.length !== 0) {
          handleModifiedProducts(modifiedProducts);
        }
        if (removedProducts.length !== 0) {
          handleRemovedProducts(removedProducts);
        }
      });

    return () => unsubscribe();
  }, []);

  return (
    <Context.Provider value={{ ...other, productsLoading }}>
      <Drawer />
      <Home />
    </Context.Provider>
  );
};

const mapState = (state) => ({
  drawerIsOpen: state.global.drawerIsOpen,
  cart: state.order.cart,
  productList: screenOrderSelectors.getSortedProducts(state)
});

const mapDispatch = (dispatch) => ({
  toggleDrawer: () => {
    dispatch({ type: screenGlobal.actions.TOGGLE_DRAWER });
  },
  addToCart: (item) => {
    dispatch({ type: screenOrder.actions.ADD_TO_CART, item });
  },
  removeFromCart: (item) => {
    dispatch({ type: screenOrder.actions.REMOVE_FROM_CART, item });
  },
  setProducts: (products) => {
    dispatch({ type: screenOrder.actions.SET_PRODUCTS, products });
  },
  handleModifiedProducts: (products) => {
    dispatch({ type: screenOrder.actions.HANDLE_MODIFIED_PRODUCTS, products });
  }
});

export default connect(mapState, mapDispatch)(Wrapper);

import React, { useState, useContext, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/styles';
import {
  Grid,
  TextField,
  Typography,
  Button,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  Card,
  Divider,
  Radio,
  CircularProgress
} from '@material-ui/core';
import { CardElement } from '@stripe/react-stripe-js';
import clsx from 'clsx';
import { Link, Redirect } from 'react-router-dom';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ErrorIcon from '@material-ui/icons/Error';
import Check from '@material-ui/icons/Check';
import LeftArrowIcon from '@material-ui/icons/ArrowLeft';
import { isMobile } from 'react-device-detect';
import TMLogo from '../../assets/img/TMLogo.png';
import { CartItemAnimation } from '../../components/home/drawer';
import '../../components/stripe/CardSelectionStyle.scss';
import Context from './context';
import totalWithShippingCalculator from '../../utilities/totalCalculatorWithShipping';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: 'black',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  }
};

const useStyles = makeStyles({
  root: {},
  formTitle: {
    fontFamily: 'Roboto Mono',
    fontSize: 15,
    fontWeight: '400'
  },
  orderSummaryRoot: {
    padding: '25px 15px 25px 15px'
  },
  orderSummaryText: {
    fontFamily: 'Roboto Mono',
    fontSize: 15,
    fontWeight: '400'
  },
  orderSummaryItems: {
    padding: '0px 10px 10px 10px'
  },
  orderSummaryTotalText: {
    fontFamily: 'Roboto Mono',
    fontSize: 13
  },
  orderSummaryTotals: {
    padding: '0px 15px 10px 15px'
  },
  cartItemRoot: {
    height: 75
  },
  image: {
    width: 29,
    height: 25
  },
  cartTitleText: {
    color: 'black',
    fontFamily: 'Roboto Mono',
    fontSize: 13
  },
  cartText: {
    color: 'black',
    fontFamily: 'Roboto Mono',
    fontSize: 13,
    textAlign: 'center'
  },
  cardText: {
    color: 'black',
    fontFamily: 'Roboto Mono',
    fontSize: 13,
    textAlign: 'left'
  },
  cardTitle: {
    color: 'grey',
    fontFamily: 'Roboto Mono',
    fontSize: 13,
    textAlign: 'center'
  },
  cardChangeText: {
    color: 'black',
    fontFamily: 'Roboto Mono',
    fontSize: 12,
    textAlign: 'center',
    cursor: 'pointer'
  },
  backButton: {
    color: 'black',
    fontFamily: 'Roboto Mono',
    fontSize: 13,
    cursor: 'pointer'
  },
  buttonProgress: {
    color: 'black',
    position: 'relative',
    bottom: 25,
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  errorMessage: {
    color: 'red',
    fontFamily: 'Roboto Mono',
    fontSize: 14,
    textAlign: 'center',
    paddingLeft: '10px'
  }
});

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'black'
    },
    '& .MuiFilledInput-underline:after': {
      borderBottomColor: 'black'
    },
    '& .MuiFilledInput-root': {
      '& fieldset': {
        borderColor: 'black'
      },
      '&:hover fieldset': {
        borderColor: 'black'
      },
      '&.MuiFilledInput-focused fieldset': {
        borderColor: 'black'
      }
    },
    '& .MuiFilledInput-underline.Mui-error:after': {
      borderBottomColor: 'red'
    }
  }
})(TextField);

const FormComponent = (props) => {
  const { fields, onChange, title } = props;
  const classes = useStyles();
  return (
    <Grid container direction="column" xs={12} sm={6} md={4} lg={3} xl={3}>
      {title && (
        <Grid item style={{ padding: '10px 15px' }}>
          <Typography className={classes.formTitle}>{title}</Typography>
        </Grid>
      )}
      {Object.keys(fields).map((key) => {
        return (
          <Grid item style={{ padding: '5px 10px' }}>
            <CssTextField
              error={fields[key].error}
              variant="filled"
              size={'small'}
              fullWidth
              placeholder={fields[key].label}
              value={fields[key].value}
              label={fields[key].label}
              inputProps={{
                style: {
                  fontFamily: 'Roboto Mono',
                  padding: fields[key].value ? '15px' : '15px'
                }
              }}
              InputLabelProps={{
                shrink: true,
                style: {
                  display: 'none',
                  fontFamily: 'Roboto Mono'
                }
              }}
              onChange={(e) => onChange(e.target.value, key)}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

const CartItem = ({ product }) => {
  const classes = useStyles();
  const { title, price, quantity, imageUrl, selectedSize } = product;
  return (
    <Grid
      item
      container
      direction="row"
      justify="space-evenly"
      alignItems="center"
      className={classes.cartItemRoot}
    >
      <Grid
        item
        container
        xs={1}
        direction="row"
        justify="flex-start"
        alignItems="center"
      >
        <img src={imageUrl} alt={`${imageUrl}`} className={classes.image} />
      </Grid>
      <Grid item xs={6} direction="row" justify="center" alignItems="center">
        <Typography className={classes.cartTitleText}>
          {title} ({selectedSize})
        </Typography>
      </Grid>
      <Grid item xs={2} direction="row" justify="center" alignItems="center">
        <Typography
          className={classes.cartText}
          style={{ textAlign: 'center' }}
        >
          {quantity}
        </Typography>
      </Grid>
      <Grid item xs={2} direction="row" justify="flex-end" alignItems="center">
        <Typography className={classes.cartText} style={{ textAlign: 'right' }}>
          ${price.displayCost}
        </Typography>
      </Grid>
    </Grid>
  );
};

const OrderSummary = (props) => {
  const { expanded, handleClick } = props;
  const { cart, selectedShipping, activeStep } = useContext(Context);
  const classes = useStyles();

  const returnObject = totalWithShippingCalculator(
    cart,
    selectedShipping.amount || 0,
    1.0825
  );

  const { tax, subTotal, total } = returnObject;

  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        justify="space-between"
        className={classes.orderSummaryRoot}
        onClick={handleClick}
      >
        <Grid item xs={8} container direction="row">
          <Typography className={classes.orderSummaryText}>
            {expanded ? 'HIDE ORDER SUMMARY' : 'SHOW ORDER SUMMARY'}
          </Typography>
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Grid>
        <Grid item xs={4}>
          <Typography
            className={classes.orderSummaryText}
            style={{ textAlign: 'right' }}
          >
            ${total.displayCost}
          </Typography>
        </Grid>
      </Grid>
      {expanded && (
        <React.Fragment>
          <Grid
            container
            direction="column"
            className={classes.orderSummaryItems}
          >
            {cart.map((product) => {
              return (
                <CartItemAnimation key={product.cartId} product={product}>
                  <CartItem product={product} />
                </CartItemAnimation>
              );
            })}
          </Grid>
          <Grid
            container
            direction="row"
            justify="space-between"
            className={classes.orderSummaryTotals}
            onClick={handleClick}
          >
            <Grid item xs={4} container direction="row">
              <Typography className={classes.orderSummaryTotalText}>
                SUBTOTAL
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography
                className={classes.orderSummaryTotalText}
                style={{ textAlign: 'right' }}
              >
                ${subTotal.displayCost}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="space-between"
            className={classes.orderSummaryTotals}
            onClick={handleClick}
          >
            <Grid item xs={4} container direction="row">
              <Typography className={classes.orderSummaryTotalText}>
                SHIPPING
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography
                className={classes.orderSummaryTotalText}
                style={{ textAlign: 'right' }}
              >
                {selectedShipping && selectedShipping.displayCost
                  ? `$${selectedShipping.displayCost}`
                  : activeStep === 0
                  ? 'CALCULATED AT NEXT STEP'
                  : 'SELECT SHIPPING METHOD'}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="space-between"
            className={classes.orderSummaryTotals}
            onClick={handleClick}
          >
            <Grid item xs={4} container direction="row">
              <Typography className={classes.orderSummaryTotalText}>
                TAXES
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography
                className={classes.orderSummaryTotalText}
                style={{ textAlign: 'right' }}
              >
                ${tax.displayCost}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="space-between"
            className={classes.orderSummaryTotals}
            style={{ paddingBottom: 35 }}
            onClick={handleClick}
          >
            <Grid item xs={4} container direction="row">
              <Typography className={classes.orderSummaryTotalText}>
                TOTAL
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography
                className={classes.orderSummaryTotalText}
                style={{ textAlign: 'right' }}
              >
                ${total.displayCost}
              </Typography>
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const NewOrderSummary = (props) => {
  const { expanded, handleClick, cart, selectedShipping } = props;
  const classes = useStyles();

  const returnObject = totalWithShippingCalculator(
    cart,
    selectedShipping.amount || 0,
    1.0825
  );

  const { tax, subTotal, total } = returnObject;

  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        justify="space-between"
        className={classes.orderSummaryRoot}
        onClick={handleClick}
      >
        <Grid item xs={8} container direction="row">
          <Typography className={classes.orderSummaryText}>
            {expanded ? 'HIDE ORDER SUMMARY' : 'SHOW ORDER SUMMARY'}
          </Typography>
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Grid>
        <Grid item xs={4}>
          <Typography
            className={classes.orderSummaryText}
            style={{ textAlign: 'right' }}
          >
            ${total.displayCost}
          </Typography>
        </Grid>
      </Grid>
      {expanded && (
        <React.Fragment>
          <Grid
            container
            direction="column"
            className={classes.orderSummaryItems}
          >
            {cart.map((product) => {
              return (
                <CartItemAnimation key={product.cartId} product={product}>
                  <CartItem product={product} />
                </CartItemAnimation>
              );
            })}
          </Grid>
          <Grid
            container
            direction="row"
            justify="space-between"
            className={classes.orderSummaryTotals}
            onClick={handleClick}
          >
            <Grid item xs={4} container direction="row">
              <Typography className={classes.orderSummaryTotalText}>
                SUBTOTAL
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography
                className={classes.orderSummaryTotalText}
                style={{ textAlign: 'right' }}
              >
                ${subTotal.displayCost}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="space-between"
            className={classes.orderSummaryTotals}
            onClick={handleClick}
          >
            <Grid item xs={4} container direction="row">
              <Typography className={classes.orderSummaryTotalText}>
                SHIPPING
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography
                className={classes.orderSummaryTotalText}
                style={{ textAlign: 'right' }}
              >
                {selectedShipping && selectedShipping.displayCost}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="space-between"
            className={classes.orderSummaryTotals}
            onClick={handleClick}
          >
            <Grid item xs={4} container direction="row">
              <Typography className={classes.orderSummaryTotalText}>
                TAXES
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography
                className={classes.orderSummaryTotalText}
                style={{ textAlign: 'right' }}
              >
                ${tax.displayCost}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="space-between"
            className={classes.orderSummaryTotals}
            style={{ paddingBottom: 35 }}
            onClick={handleClick}
          >
            <Grid item xs={4} container direction="row">
              <Typography className={classes.orderSummaryTotalText}>
                TOTAL
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography
                className={classes.orderSummaryTotalText}
                style={{ textAlign: 'right' }}
              >
                ${total.displayCost}
              </Typography>
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const ColorButton = withStyles((theme) => ({
  root: {
    color: 'white',
    backgroundColor: '#212121',
    fontFamily: 'Roboto Mono',
    borderRadius: 5,
    '&:hover': {
      backgroundColor: '#212121'
    }
  }
}))(Button);

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)'
  },
  active: {
    '& $line': {
      borderColor: '#212121'
    }
  },
  completed: {
    '& $line': {
      borderColor: '#212121'
    }
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1
  }
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#EAEAF0',
    display: 'flex',
    height: 22,
    alignItems: 'center'
  },
  active: {
    color: '#784af4'
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: '#212121'
  },
  completed: {
    color: '#212121',
    zIndex: 1,
    fontSize: 18
  }
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

const Checkout = (props) => {
  const {
    cart,
    shippingOptions,
    selectedShipping,
    setShippingOption,
    contactFields,
    shippingFields,
    setContactFields,
    setShippingFields,
    setActiveStep,
    activeStep,
    onInfoValidation,
    infoLoading,
    shippingLoading,
    paymentLoading,
    shippingOptionError,
    onShippingValidation,
    stripe,
    elements,
    errorMessage,
    onPaymentValidation,
    newOrderSummary,
    onResetCheckoutToInitialState,
    onResetProductInfo,
    productList
  } = useContext(Context);
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [errorCaption, setErrorCaption] = useState(errorMessage);
  const [paymentValidationLoading, setPaymentValidationLoading] = useState(
    false
  );

  // BILLING ADDRESS FOR LATER USE
  // const [selectedBillingAddress, setBillingAddress] = useState({
  //   option: '',
  //   address: ''
  // });

  const steps = getSteps();

  const handleNext = async () => {
    if (activeStep === 0) {
      // Validate contact info
      onInfoValidation(activeStep);
    } else if (activeStep === 1) {
      // Validate Shipping Option
      onShippingValidation(activeStep);
    } else if (activeStep === 2) {
      setPaymentValidationLoading(true);
      // Validate Payment
      if (!stripe || !elements) {
        setPaymentValidationLoading(false);
        setErrorCaption('UNKWON ERROR. PLEASE TRY AGAIN.');
        return;
      }
      // Grabbing card element
      const cardElement = elements.getElement(CardElement);

      // Show error, comeplete card element
      if (cardElement._complete === false) {
        setPaymentValidationLoading(false);
        setErrorCaption('COMPLETE PAYMENT');
        return;
      }

      setErrorCaption('');
      // Generate source with card element.
      const { error, source } = await stripe.createSource(cardElement);

      if (error) {
        setPaymentValidationLoading(false);
        setErrorCaption(error.message);
        return;
      }

      onPaymentValidation(activeStep, source);

      setPaymentValidationLoading(false);
    }
  };

  useEffect(() => {
    if (productList.length !== 0) {
      onResetProductInfo();
    }
  }, []);

  useEffect(() => {
    if (activeStep === 2 || activeStep === 3) {
      setExpanded(true);
    }
  }, [activeStep]);

  useEffect(() => {
    if (activeStep === 3 && !newOrderSummary) {
      setActiveStep(0);
    }
  }, [activeStep, newOrderSummary, setActiveStep]);

  useEffect(() => {
    setErrorCaption(errorMessage);
  }, [errorMessage]);

  const handleBack = () => {
    setErrorCaption('');
    setActiveStep(activeStep - 1);
  };

  const onContactChange = (value, key) => {
    const updatedFields = { ...contactFields };
    updatedFields[key] = { ...updatedFields[key], value };
    setContactFields(updatedFields);
  };

  const onShippingChange = (value, key) => {
    const updatedFields = { ...shippingFields };
    updatedFields[key] = { ...updatedFields[key], value };
    setShippingFields(updatedFields);
  };

  // BILLING ADDRESS FOR LATER USE
  // const onDifferentBillingChange = (value, key) => {
  //   const updatedFields = { ...selectedBillingAddress.address };
  //   updatedFields[key] = { ...updatedFields[key], value };
  //   setBillingAddress({
  //     option: 'useDifferentAddress',
  //     address: updatedFields
  //   });
  // };

  // const onBillingChange = (option) => {
  //   if (option === selectedBillingAddress.option) {
  //     return;
  //   }
  //   if (option === 'sameAsShipping') {
  //     setBillingAddress({ option: 'sameAsShipping', address: shippingFields });
  //   } else if (option === 'useDifferentAddress') {
  //     setBillingAddress({
  //       option: 'useDifferentAddress',
  //       address: {
  //         address1: {
  //           label: 'ADDRESS',
  //           error: false,
  //           value: '',
  //           required: true
  //         },
  //         address2: {
  //           label: 'APT,SUITE,ETC..',
  //           error: false,
  //           value: '',
  //           required: true
  //         },
  //         city: { label: 'CITY', error: false, value: '', required: true },
  //         state: { label: 'STATE', error: false, value: '', required: true },
  //         zipCode: {
  //           label: 'ZIP CODE',
  //           error: false,
  //           value: '',
  //           required: true
  //         }
  //       }
  //     });
  //   }
  // };

  function getSteps() {
    return ['CONTACT', 'SHIPPING', 'PAYMENT'];
  }

  if (
    cart.length === 0 &&
    (activeStep === 0 || activeStep === 1 || activeStep === 2)
  ) {
    return <Redirect to="/" />;
  }

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <React.Fragment>
            <FormComponent
              title="CONTACT"
              fields={contactFields}
              onChange={onContactChange}
            />
            <FormComponent
              title="SHIPPING"
              fields={shippingFields}
              onChange={onShippingChange}
            />
          </React.Fragment>
        );
      case 1:
        return (
          <Grid
            container
            xs={12}
            sm={6}
            md={4}
            lg={3}
            xl={3}
            direction="column"
            justify="center"
            style={{ padding: 10 }}
            spacing={2}
          >
            <Card variant="outlined">
              <Grid
                container
                direction="column"
                justify="center"
                style={{ padding: 10 }}
                spacing={1}
              >
                <Grid item container direction="row" justify="space-between">
                  <Typography className={classes.cardTitle}>Contact</Typography>
                  <Typography
                    className={classes.cardChangeText}
                    onClick={handleBack}
                  >
                    Change
                  </Typography>
                </Grid>
                <Grid item container direction="row">
                  <Typography className={classes.cardText}>
                    {contactFields['email'].value}
                  </Typography>
                </Grid>
                <Divider style={{ margin: '10px 0px' }} />
                <Grid item container direction="row" justify="space-between">
                  <Typography className={classes.cardTitle}>Ship to</Typography>
                  <Typography
                    className={classes.cardChangeText}
                    onClick={handleBack}
                  >
                    Change
                  </Typography>
                </Grid>
                <Grid item container direction="row">
                  <Typography className={classes.cardText}>
                    {[
                      shippingFields['address1'].value,
                      shippingFields['address2'].value,
                      shippingFields['city'].value,
                      shippingFields['state'].value,
                      shippingFields['zipCode'].value
                    ].join(' ')}
                  </Typography>
                </Grid>
              </Grid>
            </Card>

            <Typography
              className={classes.formTitle}
              style={{ textAlign: 'left', padding: '25px 0px 15px 0px' }}
            >
              SHIPPING METHOD
            </Typography>

            <Card
              variant="outlined"
              style={shippingOptionError ? { borderColor: 'red' } : null}
            >
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                style={{ padding: '10px 0px' }}
              >
                {shippingOptions.map((option) => {
                  return (
                    <Grid
                      item
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                      style={{ padding: '0px 10px 0px 0px' }}
                      onClick={() => setShippingOption(option)}
                    >
                      <Grid
                        item
                        xs
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                      >
                        <Grid item>
                          <Radio
                            checked={selectedShipping.value === option.value}
                            value={option.value}
                            name={option.value}
                            color="#212121"
                            size="small"
                            inputProps={{ 'aria-label': option.value }}
                          />
                        </Grid>
                        <Grid item>
                          <Typography className={classes.cardText}>
                            {option.value}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center"
                      >
                        <Typography className={classes.cardText}>
                          ${option.displayCost}
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Card>
          </Grid>
        );
      case 2:
        return (
          <Grid
            container
            xs={12}
            sm={6}
            md={4}
            lg={3}
            xl={3}
            direction="column"
            justify="center"
            style={{ padding: 10 }}
            spacing={2}
          >
            <Card variant="outlined">
              <Grid
                container
                direction="column"
                justify="center"
                style={{ padding: 10 }}
                spacing={1}
              >
                <Grid item container direction="row" justify="space-between">
                  <Typography className={classes.cardTitle}>Contact</Typography>
                  <Typography
                    className={classes.cardChangeText}
                    onClick={() => setActiveStep(0)}
                  >
                    Change
                  </Typography>
                </Grid>
                <Grid item container direction="row">
                  <Typography className={classes.cardText}>
                    {contactFields['email'].value}
                  </Typography>
                </Grid>
                <Divider style={{ margin: '10px 0px' }} />
                <Grid item container direction="row" justify="space-between">
                  <Typography className={classes.cardTitle}>Ship to</Typography>
                  <Typography
                    className={classes.cardChangeText}
                    onClick={() => setActiveStep(0)}
                  >
                    Change
                  </Typography>
                </Grid>
                <Grid item container direction="row">
                  <Typography className={classes.cardText}>
                    {[
                      shippingFields['address1'].value,
                      shippingFields['address2'].value,
                      shippingFields['city'].value,
                      shippingFields['state'].value,
                      shippingFields['zipCode'].value
                    ].join(' ')}
                  </Typography>
                </Grid>
                <Divider style={{ margin: '10px 0px' }} />
                <Grid item container direction="row" justify="space-between">
                  <Typography className={classes.cardTitle}>
                    Shipping Method
                  </Typography>
                  <Typography
                    className={classes.cardChangeText}
                    onClick={() => setActiveStep(1)}
                  >
                    Change
                  </Typography>
                </Grid>
                <Grid item container direction="row">
                  <Typography className={classes.cardText}>
                    {selectedShipping.value} - ${selectedShipping.displayCost}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
            <Typography
              className={classes.formTitle}
              style={{ textAlign: 'left', padding: '25px 0px 15px 0px' }}
            >
              PAYMENT
            </Typography>
            <Grid item style={{ padding: 0 }}>
              <CardElement options={CARD_ELEMENT_OPTIONS} />
            </Grid>
            {/* BILLING ADDRESS FOR LATER USE */}
            {/* <Typography
              className={classes.formTitle}
              style={{ textAlign: 'left', padding: '25px 0px 15px 0px' }}
            >
              BILLING ADDRESS
            </Typography>

            <Card variant="outlined">
              <Grid container direction="column" justify="center">
                <Grid
                  item
                  xs
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                  onClick={() => onBillingChange('sameAsShipping')}
                  style={{ padding: 10 }}
                >
                  <Grid item>
                    <Radio
                      checked={
                        selectedBillingAddress.option === 'sameAsShipping'
                      }
                      value={'sameAsShipping'}
                      name={'sameAsShipping'}
                      color="#212121"
                      size="small"
                      inputProps={{ 'aria-label': 'sameAsShipping' }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography className={classes.cardText}>
                      SAME AS SHIPPING ADDRESS
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
                <Grid
                  item
                  xs
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                  onClick={() => onBillingChange('useDifferentAddress')}
                  style={{ padding: 10 }}
                >
                  <Grid item>
                    <Radio
                      checked={
                        selectedBillingAddress.option === 'useDifferentAddress'
                      }
                      value={'useDifferentAddress'}
                      name={'useDifferentAddress'}
                      color="#212121"
                      size="small"
                      inputProps={{ 'aria-label': 'useDifferentAddress' }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography className={classes.cardText}>
                      USE DIFFERENT ADDRESS
                    </Typography>
                  </Grid>
                </Grid>
                {selectedBillingAddress.option === 'useDifferentAddress' && (
                  <React.Fragment>
                    <Grid
                      item
                      xs
                      container
                      direction="row"
                      justify="flex-start"
                      alignItems="center"
                      onClick={() => onBillingChange('useDifferentAddress')}
                      style={{ padding: 10 }}
                    >
                      <FormComponent
                        title={false}
                        fields={selectedBillingAddress.address}
                        onChange={onDifferentBillingChange}
                      />
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>
            </Card> */}
          </Grid>
        );

      case 3:
        return (
          <Grid
            container
            xs={12}
            sm={6}
            md={4}
            lg={3}
            xl={3}
            direction="column"
            justify="center"
            style={{ padding: 10 }}
            spacing={2}
          >
            <Card variant="outlined">
              <Grid
                container
                direction="column"
                justify="center"
                style={{ padding: 10 }}
                spacing={1}
              >
                <Grid item container direction="row" justify="space-between">
                  <Typography className={classes.cardTitle}>Contact</Typography>
                </Grid>
                <Grid item container direction="row">
                  <Typography className={classes.cardText}>
                    {newOrderSummary.contactFields['firstName'].value}{' '}
                    {newOrderSummary.contactFields['lastName'].value}
                  </Typography>
                </Grid>
                <Grid item container direction="row">
                  <Typography className={classes.cardText}>
                    {newOrderSummary.contactFields['email'].value}
                  </Typography>
                </Grid>
                <Divider style={{ margin: '10px 0px' }} />
                <Grid item container direction="row" justify="space-between">
                  <Typography className={classes.cardTitle}>Ship to</Typography>
                </Grid>
                <Grid item container direction="row">
                  <Typography className={classes.cardText}>
                    {[
                      newOrderSummary.shippingFields['address1'].value,
                      newOrderSummary.shippingFields['address2'].value,
                      newOrderSummary.shippingFields['city'].value,
                      newOrderSummary.shippingFields['state'].value,
                      newOrderSummary.shippingFields['zipCode'].value
                    ].join(' ')}
                  </Typography>
                </Grid>
                <Divider style={{ margin: '10px 0px' }} />
                <Grid item container direction="row" justify="space-between">
                  <Typography className={classes.cardTitle}>
                    Shipping Method
                  </Typography>
                </Grid>
                <Grid item container direction="row">
                  <Typography className={classes.cardText}>
                    {newOrderSummary.selectedShipping.value} - $
                    {newOrderSummary.selectedShipping.displayCost}
                  </Typography>
                </Grid>
                <Divider style={{ margin: '10px 0px' }} />
                <Grid item container direction="row" justify="space-between">
                  <Typography className={classes.cardTitle}>Payment</Typography>
                </Grid>
                <Grid item container direction="row">
                  <Typography className={classes.cardText}>
                    {newOrderSummary.charge.payment_method_details.card.brand.toUpperCase()}{' '}
                    ***
                    {newOrderSummary.charge.payment_method_details.card.last4}
                  </Typography>
                </Grid>
              </Grid>
            </Card>

            <Typography
              className={classes.formTitle}
              style={{ textAlign: 'center', padding: '25px 0px 15px 0px' }}
            >
              THANK YOU FOR YOUR PURCHASE
            </Typography>

            <Typography
              className={classes.formTitle}
              style={{ textAlign: 'center', padding: '25px 0px 15px 0px' }}
            >
              YOU WILL RECEIVE YOUR ORDER CONFIRMATION SHORTLY
            </Typography>
          </Grid>
        );

      default:
        return null;
    }
  };

  return (
    <React.Fragment>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        style={{ marginBottom: 25 }}
      >
        {activeStep === 3 ? (
          <Link to="/" onClick={() => onResetCheckoutToInitialState()}>
            <img src={TMLogo} alt="Stickware" style={{ width: 200 }} />
          </Link>
        ) : (
          <Link to="/">
            <img src={TMLogo} alt="Stickware" style={{ width: 200 }} />
          </Link>
        )}

        <Grid
          container
          xs={12}
          sm={6}
          md={4}
          lg={3}
          xl={3}
          direction="column"
          justify="center"
        >
          <Stepper
            alternativeLabel
            style={{ padding: '5px 5px 25px 5px' }}
            activeStep={activeStep}
            connector={<QontoConnector />}
          >
            {steps.map((label) => (
              <Step style={{ padding: '0px 0px' }} key={label}>
                <StepLabel StepIconComponent={QontoStepIcon}>
                  <Typography
                    style={{ fontFamily: 'Roboto Mono', fontSize: 15 }}
                  >
                    {label}
                  </Typography>
                </StepLabel>
              </Step>
            ))}
          </Stepper>

          <Typography
            className={classes.formTitle}
            style={{ textAlign: 'center', padding: '25px 0px 15px 0px' }}
          >
            ALL SALES ARE FINAL.
          </Typography>

          {activeStep === 3 ? (
            <NewOrderSummary
              cart={newOrderSummary.cart}
              selectedShipping={newOrderSummary.selectedShipping}
              handleClick={() => setExpanded(!expanded)}
              expanded={expanded}
            />
          ) : (
            <OrderSummary
              handleClick={() => setExpanded(!expanded)}
              expanded={expanded}
            />
          )}
        </Grid>

        <Grid container direction="column" justify="center" alignItems="center">
          {getStepContent(activeStep)}
          <Grid
            container
            xs={12}
            sm={6}
            md={4}
            lg={3}
            xl={3}
            direction="column"
            justify="center"
            style={{ padding: '25px 10px 10px 10px' }}
          >
            {errorCaption && (
              <Grid
                CartItem
                container
                direction="row"
                justify="center"
                alignItems="center"
                style={{ padding: '0px 0px 10px 0px' }}
              >
                <ErrorIcon style={{ color: 'red' }} />
                <Typography className={classes.errorMessage}>
                  {errorCaption}
                </Typography>
              </Grid>
            )}
            {activeStep === 3 && (
              <ColorButton
                fullWidth
                variant="contained"
                size="large"
                onClick={() => onResetCheckoutToInitialState()}
                style={{ marginBottom: 5 }}
              >
                OK
              </ColorButton>
            )}

            {activeStep < 3 && (
              <ColorButton
                fullWidth
                disabled={
                  infoLoading ||
                  shippingLoading ||
                  paymentLoading ||
                  paymentValidationLoading
                }
                variant="contained"
                size="large"
                onClick={handleNext}
                style={{ marginBottom: 5 }}
              >
                {activeStep === steps.length - 1 ? 'PAY NOW' : 'CONTINUE'}
              </ColorButton>
            )}
            {(infoLoading ||
              shippingLoading ||
              paymentLoading ||
              paymentValidationLoading) && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
            {activeStep < 3 && activeStep !== 0 && (
              <Button
                disabled={infoLoading || shippingLoading || paymentLoading}
                size="large"
                onClick={handleBack}
                // startIcon={<LeftArrowIcon />}
                // endIcon={<span></span>}
                className={classes.backButton}
              >
                {'<'} GO BACK
              </Button>
            )}
            {activeStep === 0 && (
              <Link to="/" style={{ textDecoration: 'none', width: '100%' }}>
                <Button
                  size="large"
                  fullWidth
                  // startIcon={<LeftArrowIcon />}
                  // endIcon={<span></span>}
                  className={classes.backButton}
                >
                  {'<'} BACK TO SHOP
                </Button>
              </Link>
            )}
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Checkout;

const totalCalulator = (cart, shippingCost, taxRate) => {
  let subTotal;
  try {
    subTotal = cart
      .map((cartItem) => cartItem.price.amount)
      .reduce((s, num) => {
        return s + num;
      });
  } catch (error) {
    subTotal = 0;
  }

  const subTotalAmount = Math.round(parseFloat(subTotal).toFixed(2));

  const displaySubTotal = parseFloat(subTotalAmount / 100).toFixed(2);

  const subTotalAndShipping = subTotal + shippingCost;

  const total = Math.round(parseFloat(subTotalAndShipping * taxRate));

  const displayTotal = parseFloat(total / 100).toFixed(2);

  const taxAmount = Math.round(
    parseFloat(subTotalAndShipping * parseFloat(taxRate - 1.0))
  );

  const displayTax = parseFloat(taxAmount / 100).toFixed(2);

  return {
    total: {
      displayCost: displayTotal,
      amount: total
    },
    subTotal: {
      displayCost: displaySubTotal,
      amount: subTotalAmount
    },
    tax: {
      displayCost: displayTax,
      amount: taxAmount
    }
  };
};

export default totalCalulator;

import firebase from '../../firebase';
import * as actionTypes from './actions';
import { persistReducer } from 'redux-persist';
import localforage from 'localforage';

const initialState = {
  user: null,
  isSignedIn: false,
  orders: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return {};
    case actionTypes.AUTH_LOGIN:
      return {
        ...state,
        isSignedIn: true,
        user: action.user,
        alerts: {
          ...state.alerts,
          snackbar: true,
          message: 'Logged in as ' + action.user.name,
        },
      };
    case actionTypes.AUTH_LOGOUT:
      firebase.auth().signOut();
      return {
        ...state,
        isSignedIn: false,
        user: null,
        alerts: {
          ...state.alerts,
          snackbar: true,
          message: 'Logged Out',
        },
      };
    case actionTypes.AUTH_FAILED:
      return {
        ...state,
        authError: true,
      };
    case actionTypes.SET_ORDERS:
      return {
        ...state,
        orders: action.orders,
      };
    default:
      return state;
  }
};

const persistConfig = {
  key: 'auth',
  storage: localforage,
  blacklist: ['orders'],
};

export default persistReducer(persistConfig, reducer);

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';

import Home from './screens/home/homeWrapper';
import Checkout from './screens/checkout/checkoutWrapper';
import Admin from './screens/admin/adminWrapper';

import { ElementsConsumer, Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(
  'pk_live_51HCxktHSnAS3nz7RntDWUNxgq0jRNVxwdrxFtGsGq0cUvuGkxVRXN1lcP7s73cfR3fKKPnopehKVpkxOJ6Rq4ov800yCdQhmRJ'
);

const App = () => {
  return (
    <Switch>
      <Route path="/" exact component={() => <Home />} />
      <Route
        path="/checkout"
        component={() => (
          <Elements stripe={stripePromise}>
            <ElementsConsumer>
              {({ stripe, elements }) => (
                <Checkout stripe={stripe} elements={elements} />
              )}
            </ElementsConsumer>
          </Elements>
        )}
      />
      <Route path="/" exact component={() => <Home />} />
      <Route path="/admin" exact component={() => <Admin />} />

      <Redirect exact to="/" />
    </Switch>
  );
};

class AppContainer extends Component {
  render() {
    return <App />;
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);

import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import Checkout from './Checkout';
import Context from './context';
import screenGlobal from '../../store/global';
import screenCheckout from '../../store/checkout';
import screenOrder from '../../store/order';

import { db } from '../../firebase';

const Wrapper = ({ ...other }) => {
  const { stripe, elements } = other;
  return (
    <Context.Provider value={{ ...other, stripe, elements }}>
      <Checkout />
    </Context.Provider>
  );
};

const mapState = (state) => ({
  cart: state.order.cart,
  contactFields: state.checkout.contactFields,
  shippingFields: state.checkout.shippingFields,
  activeStep: state.checkout.activeStep,
  shippingOptions: state.checkout.shippingOptions,
  selectedShipping: state.checkout.selectedShipping,
  infoLoading: state.checkout.infoLoading,
  shippingLoading: state.checkout.shippingLoading,
  paymentLoading: state.checkout.paymentLoading,
  shippingOptionError: state.checkout.shippingOptionError,
  errorMessage: state.checkout.errorMessage,
  newOrderSummary: state.checkout.newOrderSummary,
  productList: state.order.productList
});

const mapDispatch = (dispatch) => ({
  removeFromCart: (item) => {
    dispatch({ type: screenOrder.actions.REMOVE_FROM_CART, item });
  },
  setContactFields: (fields) => {
    dispatch({ type: screenCheckout.actions.SET_CONTACT_FIELDS, fields });
  },
  setShippingFields: (fields) => {
    dispatch({ type: screenCheckout.actions.SET_SHIPPING_FIELDS, fields });
  },
  setActiveStep: (step) => {
    dispatch({ type: screenCheckout.actions.SET_ACTIVE_STEP, step });
  },
  setShippingOption: (option) => {
    dispatch({ type: screenCheckout.actions.SET_SHIPPING_OPTION, option });
  },
  onInfoValidation: (step) => {
    dispatch({ type: screenCheckout.actions.INFO_VALIDATION, step });
  },
  onShippingValidation: (step) => {
    dispatch({ type: screenCheckout.actions.SHIPPING_VALIDATION, step });
  },
  onPaymentValidation: (step, source) => {
    dispatch({ type: screenCheckout.actions.PAYMENT_VALIDATION, step, source });
  },
  onResetProductInfo: () => {
    dispatch({ type: screenOrder.actions.RESET_PRODUCT_INFO });
  },
  onResetCheckoutToInitialState: () => {
    dispatch({ type: screenCheckout.actions.RESET_CHECKOUT_TO_INITIAL_STATE });
  }
});

export default connect(mapState, mapDispatch)(Wrapper);

import * as actionTypes from './actions';
import { persistReducer } from 'redux-persist';
import localforage from 'localforage';

const initialState = {
  contactFields: {
    firstName: {
      label: 'FIRST NAME',
      error: false,
      value: '',
      required: true,
      type: 'text'
    },
    lastName: {
      label: 'LAST NAME',
      error: false,
      value: '',
      required: true,
      type: 'text'
    },
    email: {
      label: 'EMAIL',
      error: false,
      value: '',
      required: true,
      type: 'email'
    },
    phoneNumber: {
      label: 'PHONE NUMBER',
      error: false,
      value: '',
      required: true,
      type: 'number'
    }
  },
  shippingFields: {
    address1: {
      label: 'ADDRESS',
      error: false,
      value: '',
      required: true,
      type: 'text'
    },
    address2: {
      label: 'APT,SUITE,ETC..',
      error: false,
      value: '',
      required: false,
      type: 'text'
    },
    city: {
      label: 'CITY',
      error: false,
      value: '',
      required: true,
      type: 'text'
    },
    state: {
      label: 'STATE',
      error: false,
      value: '',
      required: true,
      type: 'text'
    },
    zipCode: {
      label: 'ZIP CODE',
      error: false,
      value: '',
      required: true,
      type: 'number'
    }
  },
  selectedShipping: '',
  shippingOptionError: false,
  activeStep: 0,
  shippingOptions: [{ value: 'Standard', amount: 700, displayCost: '7.00' }],
  infoLoading: false,
  shippingLoading: false,
  paymentLoading: false,
  errorMessage: '',
  newOrderSummary: ''
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CONTACT_FIELDS:
      return {
        ...state,
        contactFields: action.fields
      };

    case actionTypes.SET_SHIPPING_FIELDS:
      return {
        ...state,
        shippingFields: action.fields
      };

    case actionTypes.SET_ACTIVE_STEP:
      return {
        ...state,
        activeStep: action.step
      };

    case actionTypes.SET_SHIPPING_OPTION:
      return {
        ...state,
        selectedShipping: action.option
      };

    case actionTypes.INFO_VALIDATION:
      return {
        ...state,
        infoLoading: true,
        errorMessage: ''
      };

    case actionTypes.INFO_VALIDATION_FAILED:
      return {
        ...state,
        infoLoading: false,
        contactFields: action.contactFields,
        shippingFields: action.shippingFields,
        errorMessage: action.errorMessage
      };

    case actionTypes.INFO_VALIDATION_SUCCESS:
      return {
        ...state,
        infoLoading: false,
        contactFields: action.contactFields,
        shippingFields: action.shippingFields,
        activeStep: action.nextStep,
        errorMessage: ''
      };

    case actionTypes.SHIPPING_VALIDATION:
      return {
        ...state,
        shippingLoading: true,
        errorMessage: ''
      };

    case actionTypes.SHIPPING_VALIDATION_SUCCESS:
      return {
        ...state,
        shippingLoading: false,
        activeStep: action.nextStep,
        shippingOptionError: false,
        errorMessage: ''
      };

    case actionTypes.SHIPPING_VALIDATION_FAILED:
      return {
        ...state,
        shippingLoading: false,
        shippingOptionError: true,
        errorMessage: action.errorMessage
      };

    case actionTypes.PAYMENT_VALIDATION:
      return {
        ...state,
        paymentLoading: true,
        errorMessage: ''
      };

    case actionTypes.PAYMENT_VALIDATION_SUCCESS:
      return {
        contactFields: {
          firstName: {
            label: 'FIRST NAME',
            error: false,
            value: '',
            required: true
          },
          lastName: {
            label: 'LAST NAME',
            error: false,
            value: '',
            required: true
          },
          email: { label: 'EMAIL', error: false, value: '', required: true },
          phoneNumber: {
            label: 'PHONE NUMBER',
            error: false,
            value: '',
            required: true,
            type: 'number'
          }
        },
        shippingFields: {
          address1: {
            label: 'ADDRESS',
            error: false,
            value: '',
            required: true
          },
          address2: {
            label: 'APT,SUITE,ETC..',
            error: false,
            value: '',
            required: false
          },
          city: { label: 'CITY', error: false, value: '', required: true },
          state: { label: 'STATE', error: false, value: '', required: true },
          zipCode: {
            label: 'ZIP CODE',
            error: false,
            value: '',
            required: true
          }
        },
        selectedShipping: '',
        shippingOptionError: false,
        shippingOptions: [
          { value: 'Standard', amount: 700, displayCost: '7.00' }
        ],
        infoLoading: false,
        shippingLoading: false,
        paymentLoading: false,
        errorMessage: '',
        newOrderSummary: action.newOrderSummary,
        activeStep: 3
      };

    case actionTypes.PAYMENT_VALIDATION_FAILED:
      return {
        ...state,
        paymentLoading: false,
        errorMessage: action.errorMessage
      };

    case actionTypes.RESET_CHECKOUT_TO_INITIAL_STATE:
      return initialState;

    default:
      return state;
  }
};

const persistConfig = {
  key: 'checkout',
  storage: localforage,
  blacklist: [
    'infoLoading',
    'shippingLoading',
    'paymentLoading',
    'shippingOptionError',
    'errorMessage',
    'newOrderSummary'
  ]
};

export default persistReducer(persistConfig, reducer);

import { db } from '../firebase';
import moment from 'moment';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import axios from 'axios';

const newDay = moment('12:01 AM', 'H:mmA').hour();
const lT = moment('3:00 PM', 'H:mmA').hour();
let cT = moment().hour();
const lunch = cT > newDay && cT < lT;

const ordersRef = db.collection('orders');

export function* getMenu() {
  try {
    const data = yield db
      .collection('menu_items')
      .orderBy('price', 'asc')
      .get();
    const newMenu = [];
    data.forEach((doc) => {
      if (doc.data().type === 'bowl') {
        const newData = { ...doc.data() };
        lunch
          ? (newData.price = doc.data().price.lunch)
          : (newData.price = doc.data().price.dinner);
        newMenu.push({ data: newData, id: doc.id });
      } else {
        newMenu.push({ data: doc.data(), id: doc.id });
      }
    });
    return newMenu;
  } catch (error) {
    throw new Error('Could not fetch menu.', error);
  }
}

export function* getOrders(uid) {
  try {
    const data = yield ordersRef.where('uid', '==', `${uid}`).get();
    const orders = [];
    data.forEach((doc) => {
      orders.push({ data: doc.data(), id: doc.id });
    });
    return orders;
  } catch (error) {
    throw new Error('Could not fetch orders.', error);
  }
}

// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import * as firebase from 'firebase/app';

// Add the Firebase services that you want to use
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/analytics';

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: 'AIzaSyChwncUO47_YPGjz1nDJFYFeg-zE5utu1A',
  authDomain: 'stickware-63ed5.firebaseapp.com',
  databaseURL: 'https://stickware-63ed5.firebaseio.com',
  projectId: 'stickware-63ed5',
  storageBucket: 'stickware-63ed5.appspot.com',
  messagingSenderId: '510054135313',
  appId: '1:510054135313:web:3362f447e54c028a58e3b0',
  measurementId: 'G-S0W3DH82W7'
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// Initialize Firebase Analytics
firebase.analytics();

const db = firebase.firestore();

export default firebase;

export { db };

import React, { useState, useEffect, useCallback, useContext } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { DataTypeProvider, RowDetailState } from '@devexpress/dx-react-grid';
import { CartItemAnimation } from '../../components/home/drawer';
import { DateRange, Update, MonetizationOn, Receipt } from '@material-ui/icons';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableRowDetail
} from '@devexpress/dx-react-grid-material-ui';
import {
  Grid as MTGrid,
  Toolbar as MTToolbar,
  Typography,
  IconButton
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import Card from '../../components/admin/Card/Card';
import CardHeader from '../../components/admin/Card/CardHeader.js';
import CardIcon from '../../components/admin/Card/CardIcon.js';
import CardFooter from '../../components/admin/Card/CardFooter.js';
import ProgressOverlay from '../../components/admin/progressOverlay';
import templateStyles from '../../assets/jss/material-dashboard-react/views/dashboardStyle.js';
import { orderHeaders } from './constants';
import Context from './context';
import { db } from '../../firebase';
import StatusDialog from '../../components/admin/statusDialog';

// Data Prodivder Formatters

const IdDescFormatter = ({ value }) => {
  if (value) {
    return (
      <div>
        <Typography variant="caption">
          {value.firstName} {value.lastName}{' '}
        </Typography>
      </div>
    );
  }
  return <div />;
};

const IdDescProvider = (props) => (
  <DataTypeProvider formatterComponent={IdDescFormatter} {...props} />
);

const CurrencyFormatter = ({ value }) => `$${value.total}`;

const CurrencyTypeProvider = (props) => (
  <DataTypeProvider formatterComponent={CurrencyFormatter} {...props} />
);

const DateFormatter = ({ value }) => {
  return moment(value.date * 1000).format('MM/DD/YYYY');
};

const DateTypeProvider = (props) => (
  <DataTypeProvider formatterComponent={DateFormatter} {...props} />
);

const StatusTypeFormatter = ({ value, row }) => {
  const [open, setOpen] = useState(false);
  const [selectedRow, setRow] = useState(null);

  const handleOpen = () => {
    setRow(row);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const colorCode = {
    confirmed: '#3C91E6',
    shipped: '#D4AFCD',
    'full refund': '#EE2E31',
    delivered: '#20BF55',
    'partial refund': '#F9DC5C'
  };
  return (
    <>
      <MTGrid container direction="row" alignItems="center" justify="center">
        <MTGrid
          item
          xs
          direction="row"
          style={{
            backgroundColor: colorCode[value],
            textAlign: 'center',
            padding: '0px 5px'
          }}
        >
          <Typography
            style={{
              fontFamily: 'Roboto Mono',
              fontWeight: 'bold'
            }}
            variant="caption"
          >
            {value.toUpperCase()}
          </Typography>
        </MTGrid>
        <MTGrid item xs direction="row">
          <IconButton
            onClick={handleOpen}
            disabled={value === 'full refund' || value === 'delivered'}
          >
            <EditIcon />
          </IconButton>
        </MTGrid>
      </MTGrid>
      {selectedRow && (
        <StatusDialog open={open} handleClose={handleClose} row={selectedRow} />
      )}
    </>
  );
};

const StatusTypeProvider = (props) => (
  <DataTypeProvider formatterComponent={StatusTypeFormatter} {...props} />
);

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#212121',
    width: '100%',
    height: '100%'
  },
  orderSummaryRoot: {
    padding: '25px 15px 25px 15px'
  },
  orderSummaryText: {
    fontFamily: 'Roboto Mono',
    fontSize: 15,
    fontWeight: '400'
  },
  orderSummaryItems: {
    padding: '0px 10px 10px 10px'
  },
  orderSummaryTotalText: {
    fontFamily: 'Roboto Mono',
    fontSize: 13
  },
  orderSummaryTotals: {
    padding: '0px 15px 10px 15px'
  },
  cartItemRoot: {
    height: 75
  },
  image: {
    width: 29,
    height: 25
  },
  cartTitleText: {
    color: 'black',
    fontFamily: 'Roboto Mono',
    fontSize: 13
  },
  cartText: {
    color: 'black',
    fontFamily: 'Roboto Mono',
    fontSize: 13,
    textAlign: 'center'
  }
}));

const useTemplateStyles = makeStyles(templateStyles);

const CartItem = ({ product }) => {
  const classes = useStyles();
  const { title, price, quantity, imageUrl, selectedSize } = product;
  return (
    <MTGrid
      item
      container
      direction="row"
      justify="space-evenly"
      alignItems="center"
      className={classes.cartItemRoot}
    >
      <MTGrid
        item
        container
        xs={1}
        direction="row"
        justify="flex-start"
        alignItems="center"
      >
        <img src={imageUrl} alt={`${imageUrl}`} className={classes.image} />
      </MTGrid>
      <MTGrid item xs={6} direction="row" justify="center" alignItems="center">
        <Typography className={classes.cartTitleText}>
          {title} ({selectedSize}) x {quantity}
        </Typography>
      </MTGrid>
      <MTGrid
        item
        xs={2}
        direction="row"
        justify="flex-end"
        alignItems="center"
      >
        <Typography className={classes.cartText} style={{ textAlign: 'right' }}>
          {price.displayCost}
        </Typography>
      </MTGrid>
    </MTGrid>
  );
};

const OrderSummary = ({ row }) => {
  const classes = useStyles();
  const {
    cart,
    tax,
    subTotal,
    total,
    selectedShipping,
    contactFields,
    shippingFields
  } = row;

  return (
    <React.Fragment>
      <MTGrid container direction="column">
        <MTGrid container direction="column">
          {cart.map((product) => {
            return (
              <CartItemAnimation key={product.docId} product={product}>
                <CartItem product={product} />
              </CartItemAnimation>
            );
          })}
        </MTGrid>
      </MTGrid>
      <MTGrid container direction="row">
        <MTGrid
          item
          xs={8}
          container
          direction="column"
          alignItems="flex-start"
        >
          <MTGrid
            item
            container
            direction="row"
            justify="space-between"
            xs={10}
            className={classes.orderSummaryTotals}
          >
            <MTGrid item xs={4} container direction="row">
              <Typography className={classes.orderSummaryTotalText}>
                NAME
              </Typography>
            </MTGrid>
            <MTGrid item xs={8}>
              <Typography
                className={classes.orderSummaryTotalText}
                style={{ textAlign: 'right' }}
              >
                {contactFields['firstName'].value +
                  ' ' +
                  contactFields['lastName'].value}
              </Typography>
            </MTGrid>
          </MTGrid>
          <MTGrid
            item
            xs={10}
            container
            direction="row"
            justify="space-between"
            className={classes.orderSummaryTotals}
          >
            <MTGrid item xs={4} container direction="row">
              <Typography className={classes.orderSummaryTotalText}>
                EMAIL
              </Typography>
            </MTGrid>
            <MTGrid item xs={8}>
              <Typography
                className={classes.orderSummaryTotalText}
                style={{ textAlign: 'right' }}
              >
                {contactFields['email'].value}
              </Typography>
            </MTGrid>
          </MTGrid>
          <MTGrid
            item
            xs={10}
            container
            direction="row"
            justify="space-between"
            className={classes.orderSummaryTotals}
          >
            <MTGrid item xs={4} container direction="row">
              <Typography className={classes.orderSummaryTotalText}>
                PHONE NUMBER
              </Typography>
            </MTGrid>
            <MTGrid item xs={8}>
              <Typography
                className={classes.orderSummaryTotalText}
                style={{ textAlign: 'right' }}
              >
                {contactFields['phoneNumber'] &&
                contactFields['phoneNumber'].value
                  ? contactFields['phoneNumber'].value
                  : 'N/A'}
              </Typography>
            </MTGrid>
          </MTGrid>
          <MTGrid
            item
            xs={10}
            container
            direction="row"
            justify="space-between"
            className={classes.orderSummaryTotals}
            style={{ paddingBottom: 35 }}
          >
            <MTGrid item xs={4} container direction="row">
              <Typography className={classes.orderSummaryTotalText}>
                SHIPPING ADDRESS
              </Typography>
            </MTGrid>
            <MTGrid item xs={8}>
              <Typography
                className={classes.orderSummaryTotalText}
                style={{ textAlign: 'right' }}
              >
                {(shippingFields['address1'].value || '') +
                  ' ' +
                  (shippingFields['address2'].value || '') +
                  ' ' +
                  (shippingFields['city'].value || '') +
                  ' ' +
                  (shippingFields['state'].value || '') +
                  ' ' +
                  (shippingFields['zipCode'].value || '')}
              </Typography>
            </MTGrid>
          </MTGrid>
        </MTGrid>
        <MTGrid item xs container direction="column" alignItems="center">
          <MTGrid
            item
            container
            direction="row"
            justify="space-between"
            xs
            className={classes.orderSummaryTotals}
          >
            <MTGrid item xs={4} container direction="row">
              <Typography className={classes.orderSummaryTotalText}>
                SUBTOTAL
              </Typography>
            </MTGrid>
            <MTGrid item xs={8}>
              <Typography
                className={classes.orderSummaryTotalText}
                style={{ textAlign: 'right' }}
              >
                ${subTotal.displayCost}
              </Typography>
            </MTGrid>
          </MTGrid>
          <MTGrid
            item
            xs
            container
            direction="row"
            justify="space-between"
            className={classes.orderSummaryTotals}
          >
            <MTGrid item xs={4} container direction="row">
              <Typography className={classes.orderSummaryTotalText}>
                SHIPPING
              </Typography>
            </MTGrid>
            <MTGrid item xs={8}>
              <Typography
                className={classes.orderSummaryTotalText}
                style={{ textAlign: 'right' }}
              >
                ${selectedShipping && selectedShipping.displayCost}
              </Typography>
            </MTGrid>
          </MTGrid>
          <MTGrid
            item
            xs
            container
            direction="row"
            justify="space-between"
            className={classes.orderSummaryTotals}
          >
            <MTGrid item xs={4} container direction="row">
              <Typography className={classes.orderSummaryTotalText}>
                TAX
              </Typography>
            </MTGrid>
            <MTGrid item xs={8}>
              <Typography
                className={classes.orderSummaryTotalText}
                style={{ textAlign: 'right' }}
              >
                ${tax.displayCost}
              </Typography>
            </MTGrid>
          </MTGrid>
          <MTGrid
            item
            xs
            container
            direction="row"
            justify="space-between"
            className={classes.orderSummaryTotals}
            style={{ paddingBottom: 35 }}
          >
            <MTGrid item xs={4} container direction="row">
              <Typography className={classes.orderSummaryTotalText}>
                TOTAL
              </Typography>
            </MTGrid>
            <MTGrid item xs={8}>
              <Typography
                className={classes.orderSummaryTotalText}
                style={{ textAlign: 'right' }}
              >
                ${total.displayCost}
              </Typography>
            </MTGrid>
          </MTGrid>
        </MTGrid>
      </MTGrid>
    </React.Fragment>
  );
};

const RowDetail = ({ row }) => {
  const classes = useStyles();

  return (
    <MTGrid container xs={12} direction="column">
      <OrderSummary row={row} />
    </MTGrid>
  );
};

export default function Orders() {
  const classes = useStyles();
  const tClasses = useTemplateStyles();
  const {
    orders,
    setOrders,
    handleModifiedOrders,
    grossTotal,
    sendBulkEmail
  } = useContext(Context);

  const uniqueOrderIds = [...new Set(orders.map((order) => order.docId))];

  const handleAddedOrders = (orders) => {
    setOrders(orders);
  };

  // useEffect(() => {
  //   const emailList = orders
  //     .filter((order) => order.status !== 'full refund')
  //     .map((order) => {
  //       return {
  //         docId: order.docId,
  //         email: order.contactFields['email'].value,
  //         name:
  //           order.contactFields['firstName'].value +
  //           ' ' +
  //           order.contactFields['lastName'].value
  //       };
  //     });
  //   sendBulkEmail({
  //     emailList: emailList
  //   });
  // }, []);

  useEffect(() => {
    const unsubscribe = db
      .collection('orders')
      .orderBy('date', 'desc')
      .onSnapshot(function (snapshot) {
        let addedOrders = [];
        let modifiedOrders = [];
        snapshot.docChanges().forEach((change) => {
          if (change.type === 'added') {
            const order = change.doc.data();
            if (order && order.docId && !uniqueOrderIds.includes(order.docId)) {
              addedOrders.push(order);
            }
          }
          if (change.type === 'modified') {
            const product = change.doc.data();
            modifiedOrders.push(product);
          }
        });
        if (addedOrders.length !== 0) {
          handleAddedOrders(addedOrders);
        }
        if (modifiedOrders.length !== 0) {
          handleModifiedOrders(modifiedOrders);
        }
      });
    return () => unsubscribe();
  }, []);

  const getRowId = (row) => row.docId;

  const { columnData, columnWidth } = orderHeaders;
  const [currencyColumns] = useState(['total']);
  const [fullNameColumns] = useState(['fullName']);
  const [dateColumns] = useState(['date']);
  const [statusColumns] = useState(['status']);
  const [expandedRowIds, setExpandedRowIds] = useState([]);

  const onSetExpandedRowId = useCallback(
    (rowID) => {
      const result = rowID.filter((e) => !expandedRowIds.includes(e));
      setExpandedRowIds(result);
      // Fetch Order Data
    },
    [expandedRowIds]
  );

  const onRowDetail = useCallback(({ row }) => {
    return <RowDetail row={row} />;
  }, []);

  return (
    <div className={classes.root}>
      <MTGrid container justify="center">
        <MTGrid item xs={12} sm={6} md={4} lg={4} style={{ padding: 15 }}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <Receipt />
              </CardIcon>
              <p className={tClasses.cardCategory}>Orders</p>
              <h3 className={tClasses.cardTitle}>{orders.length}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={tClasses.stats}>
                <DateRange />
                Since drop
              </div>
            </CardFooter>
          </Card>
        </MTGrid>
        <MTGrid item xs={12} sm={6} md={4} lg={4} style={{ padding: 15 }}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <MonetizationOn />
              </CardIcon>
              <p className={tClasses.cardCategory}>Gross Total</p>
              <h3 className={tClasses.cardTitle}>${grossTotal}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={tClasses.stats}>
                <Update />
                Last Updated
              </div>
            </CardFooter>
          </Card>
        </MTGrid>
        {orders && (
          <MTGrid
            container
            style={{
              backgroundColor: 'white',
              borderRadius: 5,
              margin: '0px 15px 40px 15px'
            }}
            xs={12}
            sm={12}
            md={8}
          >
            <Grid rows={orders} columns={columnData} getRowId={getRowId}>
              <IdDescProvider for={fullNameColumns} />
              <CurrencyTypeProvider for={currencyColumns} />
              <StatusTypeProvider for={statusColumns} />
              <DateTypeProvider for={dateColumns} />
              <RowDetailState
                expandedRowIds={expandedRowIds}
                onExpandedRowIdsChange={onSetExpandedRowId}
              />
              <Table />
              <TableHeaderRow />
              <TableRowDetail contentComponent={onRowDetail} />
            </Grid>
          </MTGrid>
        )}
      </MTGrid>
    </div>
  );
}

import React, { useState, useEffect, useCallback, useContext } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { CartItemAnimation } from '../../components/home/drawer';
import { DateRange, Update, MonetizationOn, Receipt } from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit';
import { Grid, Toolbar, Typography } from '@material-ui/core';
import Card from '../../components/admin/Card/Card';
import CardHeader from '../../components/admin/Card/CardHeader.js';
import CardIcon from '../../components/admin/Card/CardIcon.js';
import CardFooter from '../../components/admin/Card/CardFooter.js';
import templateStyles from '../../assets/jss/material-dashboard-react/views/dashboardStyle.js';
import { productHeaders } from './constants';
import Context from './context';
import { db } from '../../firebase';
import ProductItem from '../../components/admin/productItem';
import ProductDialog from '../../components/admin/productDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#212121',
    width: '100%',
    minHeight: '100vh',
    paddingTop: 25
  },
  image: {
    width: 105,
    height: 95
  },
  cardTitle: {
    fontFamily: 'Roboto Mono',
    color: 'black',
    fontSize: 18
  },
  cardPrice: {
    fontFamily: 'Roboto Mono',
    color: 'black',
    fontSize: 18
  }
}));

export default function PersistentDrawerLeft() {
  const classes = useStyles();
  const { products, setAdminProducts, handleModifiedProducts } = useContext(
    Context
  );
  const [open, setOpen] = React.useState(false);
  const [selectedProduct, setSelectedProduct] = React.useState(null);

  const handleOpen = (product) => {
    setSelectedProduct(product);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedProduct(null);
  };

  const uniqueProductIds = [
    ...new Set(products.map((product) => product.docId))
  ];

  const handleAddedProducts = (orders) => {
    setAdminProducts(orders);
  };

  useEffect(() => {
    const unsubscribe = db
      .collection('products')
      .orderBy('position', 'asc')
      .onSnapshot(function (snapshot) {
        let addedProducts = [];
        let modifiedProducts = [];
        snapshot.docChanges().forEach((change) => {
          if (change.type === 'added') {
            const product = change.doc.data();
            if (
              product &&
              product.docId &&
              !uniqueProductIds.includes(product.docId)
            ) {
              addedProducts.push(product);
            }
          }
          if (change.type === 'modified') {
            const product = change.doc.data();
            modifiedProducts.push(product);
          }
        });
        if (addedProducts.length !== 0) {
          handleAddedProducts(addedProducts);
        }
        if (modifiedProducts.length !== 0) {
          handleModifiedProducts(modifiedProducts);
        }
      });
    return () => unsubscribe();
  }, []);

  return (
    <div className={classes.root}>
      {selectedProduct && (
        <ProductDialog
          open={open}
          handleClose={handleClose}
          product={selectedProduct}
        />
      )}

      <Grid container>
        {products.map((product) => {
          return (
            <ProductItem
              product={product}
              onClick={() => handleOpen(product)}
            />
          );
        })}
      </Grid>
    </div>
  );
}

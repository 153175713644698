import { createSelector } from 'reselect';

const orderState = (state) => state.order;

export const getCart = createSelector(orderState, (state) => state.cart);

export const getSortedProducts = createSelector(orderState, (state) => {
  const sortedProducts = state.productList.sort(
    (a, b) => a.position - b.position
  );
  return sortedProducts;
});
